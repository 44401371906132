export const config = {
  main: {
    groupList: [
      [
        {
          title: 'Crédito',
          links: [
            {
              label: 'Empréstimo com garantia de veículo',
              href: 'https://www.creditas.com/emprestimo/garantia-veiculo',
            },
            {
              label: 'Empréstimo com garantia de imóvel',
              href: 'https://www.creditas.com/emprestimo/garantia-imovel',
            },
            {
              label: 'Empréstimo consignado',
              href: 'https://www.creditas.com/emprestimo/consignado-privado',
            },
            {
              label: 'Empréstimo online',
              href: 'https://www.creditas.com/emprestimo-online',
            },
            {
              label: 'Empréstimo para quitar dívidas',
              href: 'https://www.creditas.com/dividas',
            },
          ],
        },
        {
          title: 'Creditas Store',
          links: [
            {
              label: 'Smartphones',
              href: 'https://www.creditas.com/store/45',
            },
            {
              label: 'Eletrodomésticos',
              href: 'https://www.creditas.com/store/54',
            },
            {
              label: 'Loja Oficial Apple',
              href: 'https://www.creditas.com/store/apple',
            },
          ],
        },
      ],
      [
        {
          title: 'Carros',
          links: [
            {
              label: 'Financiamento',
              href: 'https://www.creditas.com/financiamento-veiculos',
            },
            {
              label: 'Compra',
              href: 'https://auto.creditas.com',
            },
            {
              label: 'Refinanciamento',
              href: 'https://www.creditas.com/refinanciamento-veiculos',
            },
          ],
        },
        {
          title: 'Imóveis',
          links: [
            {
              label: 'Reforma',
              href: 'https://www.creditas.com/imovel/projeto',
            },
            {
              label: 'Venda',
              href: 'https://www.creditas.com/imovel/vender',
            },
            {
              label: 'Refinanciamento',
              href: 'https://www.creditas.com/refinanciamento-imovel',
            },
          ],
        },
      ],
      [
        {
          title: 'Empresas',
          links: [
            {
              label: 'Creditas @Work',
              href: 'https://www.creditas.com/beneficios',
            },
            {
              label: 'Empréstimo consignado',
              href: 'https://www.creditas.com/emprestimo/consignado-privado',
            },
            {
              label: 'Creditas Store',
              href: 'https://www.creditas.com/store',
            },
            {
              label: 'Antecipação de salário',
              href:
                'https://www.creditas.com/beneficios-corporativos/antecipacao-salario',
            },
            {
              label: 'Cartão de benefícios flexíveis',
              href: 'https://www.creditas.com/beneficios/cartao',
            },
            {
              label: 'Previdência Privada',
              href:
                'https://vantagens.creditas.com.br/previdencia-privada/?__hstc=235821022.b6c153a0af4bd4e9fa84bfd90d5f4c99.1618581115869.1619111601209.1619115624889.14&__hssc=235821022.1.1619115624889&__hsfp=2675964914&_ga=2.33936165.505428881.1619099607-2045109596.1618581114',
            },
          ],
        },
        {
          title: 'Ajuda e atendimento',
          links: [
            {
              label: 'Perguntas frequentes',
              href: 'https://ajuda.creditas.com/hc/pt-br',
            },
            {
              label: 'Canais de atendimento',
              href: 'https://www.creditas.com/canais-de-atendimento',
            },
            {
              label: 'Imprensa',
              href: 'mailto:imprensa@creditas.com.br',
            },
          ],
        },
      ],
      [
        {
          title: 'Institucional',
          links: [
            {
              label: 'Quem Somos',
              href: 'https://www.creditas.com/quem-somos',
            },
            {
              label: 'Exponencial',
              href: 'https://www.creditas.com/exponencial/',
            },
            {
              label: 'Tech blog',
              href: 'https://medium.com/creditas-tech',
            },
            {
              label: 'Trabalhe conosco',
              href: 'https://boards.greenhouse.io/creditas/',
            },
            {
              label: 'Developers - API',
              href: 'https://developers.creditas.com.br/docs',
            },
            {
              label: 'Mapa do site',
              href: 'https://www.creditas.com/mapa-do-site',
            },
            {
              label: 'Seja parceiro',
              href: 'https://www.creditas.com/parceiros',
            },
            {
              label: 'Relação com investidores',
              href: 'http://ir.creditas.com/',
            },
            {
              label: 'English',
              href: 'https://www.creditas.com/en',
            },
            {
              label: 'Español',
              href: 'https://www.creditas.com/es',
            },
          ],
        },
      ],
    ],
  },
  legal: {
    text: [
      'A Creditas é uma plataforma digital que atua como correspondente Bancário e Sociedade de Crédito Direto para facilitar o processo de contratação de empréstimos. Como Correspondente Bancário, seguimos as diretrizes da Resolução nº 3.954 e como Sociedade de Crédito Direto a resolução nº 4.656, ambas do Banco Central do Brasil. Somos correspondentes bancários das seguintes instituições: Companhia Hipotecária Piratini - CHP (CNPJ 18.282.093/0001-50), Sorocred Crédito Financiamento e Investimento S/A (CNPJ 04.814.563/0001-74), Creditas Sociedade de Crédito Direto S/A (CNPJ 32.997.490/0001-39) e Socinal S.A. – Crédito, financiamento e investimento (CNPJ 03.881.423/0001-56) nos termos do artigo 2º, da Resolução CMN nº 3.954, de 24 de fevereiro de 2011.',
      'Informações adicionais relativas ao Empréstimo com Garantia de Automóvel: mínimo 18 meses e máximo 60 meses - Empréstimo com Garantia de Imóvel: mínimo 60 meses e máximo 240 meses. Exemplo com Garantia de Imóvel - Empréstimo de R$ 100.000,00 para pagar em 20 anos (240 meses) – Prestação inicial de R$ 1.144.63, com uma taxa de juros de 0,84% ao mês + IPCA, Sistema de Amortização Price. CET de 12,50% ao ano - Empréstimo Consignado Privado: o prazo de pagamento mínimo é de 6 meses e máximo de 48 meses - Custo Efetivo Total (CET): mínimo de 18,52% e máximo de 60,78% ao ano. Exemplo - Empréstimo de R$ 15.000,00 para pagamento em 48 meses – Total de 48 parcelas de R$ 433,65. Valor total a pagar igual a R$ 20.815,26 com uma taxa de juros de 0,99% ao mês (16,62% ao ano).',
      'Creditas Soluções Financeiras Ltda. - CNPJ 17.770.708/0001-24, localizada na Avenida das Nações Unidas, nº 12995 - Bloco I, térreo, Edifício Centenário Plaza, Brooklin Paulista, na Cidade de São Paulo, Estado de São Paulo, CEP 04578-911 | Creditas Sociedade de Crédito Direto S/A - CNPJ 32.997.490/0001-39, localizada na Avenida das Nações Unidas, nº 12995 - Bloco I, 1º andar, Edifício Centenário Plaza, Brooklin Paulista, na Cidade de São Paulo, Estado de São Paulo, CEP 04578-911.',
    ],
    subLinks: [
      {
        label: 'Central da Transparência',
        href: 'https://www.creditas.com/transparencia',
      },
      {
        label: 'Política de privacidade',
        href: 'https://www.creditas.com/legal/politica-privacidade',
      },
      {
        label: 'Termos de uso',
        href: 'https://www.creditas.com/legal/termos-condicoes',
      },
      {
        label: 'Ouvidoria dos parceiros',
        href: 'https://www.creditas.com/legal/ouvidoria-parceiros',
      },
      {
        label: 'Tabela de Tarifas',
        href:
          'https://www.creditas.com/static/images/tabela-site-896bb924f4.e46e1a42.jpg',
      },
    ],
  },
}
